import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import RadioButton from 'material-ui/RadioButton/RadioButton';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  TextField,
  RadioButtonGroup,
  Tag,
  CardEmpty,
  Container,
  CardFooter,
} from 'src/components/IMUI';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import createQuestion from './createQuestion';
import rightAlignLanguages from './rightAlignLanguages';
import cls from './SingleChoice.module.css';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const cx = classNames.bind(cls);

@createQuestion
@connect(pick(['survey', 'surveyAnswers']))
class SingleChoice extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    question: PropTypes.object,
    answer: PropTypes.object,
    index: PropTypes.number.isRequired,
    isReview: PropTypes.bool,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    changeAnswerOtherChoiceValue: PropTypes.func,
    t: PropTypes.func,
  };

  static validate(question, answers = '', otherValue = '') {
    if (question.required && typeof answers !== 'string') {
      return <T>Please select a valid answer</T>;
    }
    if (question.required && !answers && !otherValue) {
      return <T>Please select an answer</T>;
    }
    return null;
  }

  onChange = (value, tag_id) => {
    const newValue = value !== this.props.value ? value : '';
    this.props.onChange?.(newValue, [tag_id].flat());
    this.props.changeAnswerOtherChoiceValue?.(this.props.question.id, '');
  };

  onChangeOtherValue = (data) => {
    this.props.changeAnswerOtherChoiceValue?.(this.props.question.id, data);
    this.props.onChange?.(undefined, []);
  };

  getTag = (tag_id) =>
    this.props.tagsWithTagGroups.find((st) => st.id === tag_id);

  getI18nString = (path, altPath) =>
    getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      this.props.question,
      path,
      altPath
    );

  getAnswerI18nString = (answer, path, altPath) =>
    getSurveyI18n(this.props.survey, answer, path, altPath);

  renderOption = (option, optionIndex) => {
    const { readOnly, answer, survey } = this.props;
    const title = this.getI18nString(option.id, [
      'options',
      optionIndex,
      'title',
    ]);
    const rightAlign = rightAlignLanguages.includes(survey.activeLanguage);

    return (
      <RadioButton
        className={cx('radio', {
          'radio-readonly': readOnly,
          'radio-empty': answer.value !== option.title,
        })}
        key={option.id}
        onClick={() => this.onChange(option.title, option.tag_id)}
        label={
          <div
            className={cls.label}
            style={rightAlign ? { paddingRight: '14px' } : {}}
          >
            {title}
          </div>
        }
        value={
          this.props.survey.activeLanguage !==
            this.props.survey.default_language && answer.value === option.title
            ? answer.i18n[survey.activeLanguage]?.value || option.title
            : option.title
        }
        style={rightAlign ? { marginRight: '-14px' } : null}
      />
    );
  };
  renderTags() {
    if (!this.props.isReview || this.props.isAnswerMode) return null;
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!this.props.answer.tags.length && this.props.isEditing && (
            <TagNotProvided />
          )}
          {this.props.answer.tags
            .map((i) => this.getTag(i))
            .map(
              (tag) =>
                tag && (
                  <Tag
                    key={tag.id}
                    inline
                    label={tag.title}
                    tip={(tag.tagGroup || {}).title}
                  />
                )
            )}
        </Container>
      </CardFooter>
    );
  }

  render() {
    const { index, question, value, readOnly, isAnswerMode, answer } =
      this.props;
    if (!question.options?.length)
      return (
        <CardEmpty>
          Please use `Quick add` to add multiple responses to your questions or
          `Add option` to add response choices one by one
        </CardEmpty>
      );

    return (
      <div>
        <RadioButtonGroup
          name={`radio-group_${index}`}
          valueSelected={value}
          readOnly={readOnly}
        >
          {question.options.map(this.renderOption)}
        </RadioButtonGroup>
        {question.hasOther && (
          <TextField
            label={`${this.props.t('Other')}:`}
            multiLine
            name={`text_${index}`}
            value={
              this.props.survey.activeLanguage ===
              this.props.survey.default_language
                ? answer.otherValue
                : this.getAnswerI18nString(
                    answer,
                    'other_value',
                    'other_value'
                  ) || answer.otherValue
            }
            onChange={this.onChangeOtherValue}
            placeholder={
              this.getAnswerI18nString(answer, 'other_value', 'other_value')
                ? undefined
                : this.props.t('Please describe')
            }
            readOnly={readOnly}
            noValidation
            flat={!isAnswerMode}
            border={!isAnswerMode}
            flatDark={isAnswerMode}
            wrapperClassName="mt-1"
          />
        )}
        {this.renderTags()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}

const enhancedComponent = withTranslators(SingleChoice);
enhancedComponent.validate = SingleChoice.validate;
export default enhancedComponent;
